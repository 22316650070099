<template>
  <b-field :label="label" horizontal class="field-nested">
    <b-input v-if="disabled" disabled :value="locationName"> </b-input>
    <treeselect
      v-else
      name="location"
      ref="treeselect"
      :options="filteredLocations"
      :max-height="200"
      :value="value"
      @input="v => $emit('input', v)"
      @select="nodeSelected"
      placeholder="Select Location..."
      :load-options="loadOptions"
      :required="required"
      :class="{ notFullWidth: notFullWidth }"
    />
    <loading-data v-if="isLoading" />
  </b-field>
</template>

<script>
// import the component
import Treeselect, { LOAD_CHILDREN_OPTIONS, LOAD_ROOT_OPTIONS } from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

import { mapGetters } from "vuex";
import LoadingData from "./LoadingData.vue";

export default {
  components: { Treeselect, LoadingData },
  props: {
    value: { require: true },
    disabled: { type: Boolean },
    locationName: { type: String },
    label: { type: String, default: "Location" },
    required: { type: Boolean },
    notFullWidth: { type: Boolean },
    startLocationId: { type: Number },
    mainLocationsOnly: { type: Boolean }
  },
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    ...mapGetters("locations", ["locations", "locationsFrom", "locationsMainOnly", "selectedLocationId"]),
    filteredLocations() {
      if (!this.locations) return null;

      if (this.startLocationId) return this.locationsFrom(this.startLocationId);
      if (this.mainLocationsOnly) return this.locationsMainOnly;

      return this.locations;
    }
  },
  created() {
    // default to current selection if field is required
    if (!this.value) {
      if (this.selectedLocationId && this.required) {
        this.$emit("input", this.selectedLocationId);
      } else {
        this.selectFirstOption();
      }
    }
  },
  watch: {
    value: "selectLocation"
  },
  methods: {
    async loadOptions({ action, parentNode }) {
      if (action === LOAD_ROOT_OPTIONS) {
        this.isLoading = true;
        await this.$store.dispatch("locations/getLocations");
        this.isLoading = false;
        // select existing options, this will update the SelectedLocationName
        if (this.value) {
          this.selectLocation(this.value);
          // otherwise select first option
        } else if (this.required) {
          this.selectFirstOption();
        }
      } else if (action === LOAD_CHILDREN_OPTIONS) {
        await this.$store.dispatch("locations/getSubLocations", parentNode);
      }
    },
    selectLocation(locationId) {
      if (!locationId) return;
      if (this.locations && this.locations.length > 0) {
        // A bit of hack to load child options of the selected value. This is needed when programatically selecting the location
        // such as the clicking on location in the graph
        // Need to find node matching current location
        const node = this.findNode(this.locations, locationId);
        if (node) {
          // ensure we got children for selected node
          if (node.children === null) {
            node.raw = node; // had to set this values to prevent errors on toggleExpanded, but its not actuall expanding the view
            node.childrenStates = {};
            this.$refs.treeselect.toggleExpanded(node);
          }

          // to ensure the setSelectedLocationName get set, we set the node here also
          this.$store.dispatch("locations/selectLocationNode", node);
        } else {
          this.$store.dispatch("locations/getUnknownLocation", locationId);
        }
      }
    },
    selectFirstOption() {
      if (this.locations && this.locations.length > 0) {
        const first = this.locations[0];
        this.nodeSelected({
          id: first.id,
          label: first.label
        });
      }
    },
    nodeSelected(node) {
      this.$emit("input", node.id);
      this.$store.dispatch("locations/selectLocationNode", node);
    },
    findNode(node, locationId) {
      if (!node) return null;
      if (node.id === locationId) return node;
      if (Array.isArray(node)) {
        var match = null;
        node.forEach(child => {
          const foundNode = this.findNode(child, locationId);
          if (foundNode) match = foundNode;
        });
        return match;
      }
      return this.findNode(node.children, locationId);
    }
  }
};
</script>

<style>
.vue-treeselect__menu-container {
  min-width: 300px;
}
.notFullWidth .vue-treeselect__control {
  width: inherit;
}
.notFullWidth .vue-treeselect__value-container {
  width: 90%;
}
</style>
